* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Encode Sans Expanded', sans-serif; */
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  height: -webkit-fill-available;
}

input {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

.Toastify__toast--success {
  background-color: rgba(10, 201, 122, 1)
}

.Toastify__toast-body {
  text-align: center;
}